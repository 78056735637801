<template>
  <div
    class="relative mx-[24px] block flex-col items-center gap-2 overflow-clip md:mx-[64px] md:gap-8"
  >
    <CarouselArrows
      v-if="displayDefaultArrows"
      class="my-4"
      :can-move-left="loop ? true : currentIndex > 0"
      :can-move-right="loop ? true : !isEndReached"
      :size="arrowsSize"
      @prev="prevSlide"
      @next="nextSlide"
    />
    <div
      @touchstart="onStartTouch"
      @touchmove="onTouchMove"
      @touchend="onEndTouch"
      @mousedown.prevent="onMouseDown"
      @mousemove.prevent="onMouseMove"
      @mouseup.prevent="onMouseUp"
      @mouseleave.prevent="onMouseLeave"
    >
      <div
        ref="carousel"
        class="flex flex-row gap-4 transition-transform duration-300 ease-in-out"
        :class="{ 'gap-0': hasGoodPlanSpace }"
        :style="style"
      >
        <div v-for="(slide, index) in slidesData" :key="index">
          <component
            :is="slideComponent"
            :data="slide"
            :has-mobile="hasMobile"
            @click="emit('on-slide-change', slide)"
          />

          <div v-if="index !== slidesData.length - 1 && hasGoodPlanSpace" class="space" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { BREAKPOINTS } from '@/lib/variables';

const props = withDefaults(
  defineProps<{
    slidesData: Object[];
    slideComponent: Object;
    slidesPerPage?: number;
    displayDefaultArrows?: boolean;
    arrowsSize?: 'small' | 'medium' | 'large';
    loop?: boolean;
    hasMobile?: boolean;
    hasGoodPlanSpace?: boolean;
  }>(),
  {
    slidesPerPage: 1,
    arrowsSize: 'medium',
    hasGoodPlanSpace: false
  }
);

const emit = defineEmits<{
  (event: 'on-slide-change', slide: Object): void;
}>();

const GAP = 16;
const TOUCH_DIFF_X = 50;
const MOUSE_DIFF_X = 100;
const carousel = ref<HTMLDivElement | null>(null);
const currentIndex = ref(0);
const isDragging = ref(false);
const startTouchX = ref(0);
const isEndReached = ref(false);

const isMobile = computed(() => process.client && window.innerWidth < BREAKPOINTS.mobile);

const slidesPerPage = computed(() => (isMobile.value ? 1 : props.slidesPerPage));

const style = ref({
  transform: 'translateX(0) translateZ(0)'
});

watch(
  () => currentIndex.value,
  () => {
    const firstChild = carousel.value?.children[0] as HTMLElement;
    const containerWidth = carousel.value?.parentElement?.offsetWidth;

    if (firstChild && containerWidth) {
      const slideWidth = firstChild.offsetWidth;
      const totalSlides = props.slidesData.length;
      const slidesInView = isMobile.value ? 1 : containerWidth / (slideWidth + GAP);
      const maxTranslateX = (totalSlides - slidesInView) * (slideWidth + GAP);
      const slideTranslateX = currentIndex.value * (slideWidth + GAP);

      if (slideTranslateX >= maxTranslateX) {
        isEndReached.value = true;
      } else {
        isEndReached.value = false;
      }

      style.value = {
        transform: `translateX(-${Math.min(slideTranslateX, maxTranslateX)}px) translateZ(0)`
      };
    }

    const currentSlide = props.slidesData[currentIndex.value];
    if (currentSlide) {
      emit('on-slide-change', currentSlide);
    }
  }
);

function nextSlide() {
  if (currentIndex.value < props.slidesData.length - slidesPerPage.value) {
    currentIndex.value += slidesPerPage.value;
  } else if (props.loop) {
    currentIndex.value = 0;
  }
}

function prevSlide() {
  if (currentIndex.value > 0) {
    currentIndex.value -= slidesPerPage.value;
  } else if (props.loop) {
    currentIndex.value = props.slidesData.length - slidesPerPage.value;
  }
}

function onStartTouch(event: TouchEvent) {
  isDragging.value = true;
  const eventTouch = event.touches[0];
  if (!eventTouch) return;
  startTouchX.value = eventTouch.clientX;
}

function onTouchMove(event: TouchEvent) {
  const eventTouch = event.touches[0];
  if (!isDragging.value || !eventTouch) return;
  const diffX = eventTouch.clientX - startTouchX.value;
  if (Math.abs(diffX) > TOUCH_DIFF_X) {
    if (diffX > 0) {
      prevSlide();
    } else {
      nextSlide();
    }
    isDragging.value = false;
  }
}

function onEndTouch() {
  isDragging.value = false;
}

function onMouseDown(event: MouseEvent) {
  isDragging.value = true;
  startTouchX.value = event.clientX;
}

function onMouseMove(event: MouseEvent) {
  if (!isDragging.value) return;
  const diffX = event.clientX - startTouchX.value;
  if (Math.abs(diffX) > MOUSE_DIFF_X) {
    if (diffX > 0) {
      prevSlide();
    } else {
      nextSlide();
    }
    isDragging.value = false;
  }
}

function onMouseUp() {
  isDragging.value = false;
}

function onMouseLeave() {
  isDragging.value = false;
}

defineExpose({
  nextSlide,
  prevSlide,
  currentIndex,
  slidesPerPage,
  isEndReached
});
</script>

<style lang="scss" scoped>
.space {
  width: 14px;
  height: 55px;
  margin: 0 20px;

  opacity: 0.3;
  border-bottom: 1px solid;
}
</style>
